
    import { IonList, IonItem, IonIcon, IonCard, IonImg, IonInput } from '@ionic/vue';
    import { heartOutline, ticketOutline, heart } from 'ionicons/icons';
    import { mapState } from 'vuex'
    import { EventCategoryModel, EventApiClient, EventModel, MemberApiClient } from '../../generated';
    import { defineComponent } from 'vue';
    import { LanguageMixin } from '@/mixins/LanguageMixin';
    import { SharedMixin } from '@/mixins/SharedMixin';
    import moment from 'moment';
    import { store } from '../../store/store';

    export default defineComponent({
        name: 'Events Overview',
        mixins: [LanguageMixin, SharedMixin],
        components: {
            IonList,
            IonItem,
            IonIcon,
            IonCard,
            IonImg,
            IonInput
        },

        data() {
            return {
                eventId: this.$route.params.event as string,
                currentEvent: new EventCategoryModel(),
                currentEventDates: Array<EventModel>(),
                filteredEventDates: Array<EventModel>(),
                eventCount: 0 as number,
                CalenderUrl: '',
                toggleEvents: true as boolean,
                favorite: false,
                favoriteEvents: localStorage.getItem("favoriteEvents"),
                loggedIn(): boolean {
                    return localStorage.getItem('loggedIn') == 'true';
                }
            }
        },
        watch: {
            '$route.params': {
                handler(newValue) {
                    this.favoriteEvents = localStorage.getItem("favoriteEvents");
                },
                immediate: true,
            },
        },
        computed: mapState(['events']),
        setup() {
            return {
                heartOutline,
                ticketOutline,
                heart
            }
        },
        ionViewDidEnter() {
            this.loadData();
        },
        methods: {
            loadData: function () {
                const eventsClient = new EventApiClient();

                if (this.eventId) {
                    eventsClient.get(parseInt(this.eventId), store.state.currentLanguage).then(result => {
                        if (result != null) {
                            this.currentEvent = result;
                            if (result.eventDates != undefined) {
                                this.currentEventDates = result.eventDates;
                                this.filteredEventDates = result.eventDates.filter((item: EventModel) => {
                                    const now = new Date();
                                    return this.addDay(item.timeUntil) > now
                                });
                            }
                        }
                        this.eventCount = this.filteredEventDates.length;
                    });
                }
            },
            filterEvents: function () {
                this.toggleEvents = !this.toggleEvents;
                debugger

                if (this.toggleEvents) {
                    this.filteredEventDates = this.currentEventDates.filter((item: EventModel) => {
                        const now = new Date();
                        const newDate = this.addDay(item.timeUntil);
                        return newDate > now
                    });
                } else {
                    this.filteredEventDates = this.currentEventDates.filter((item: EventModel) => {
                        const now = new Date();
                        return this.addDay(item.timeUntil) < now
                    });
                }
                this.eventCount = this.filteredEventDates.length;
            },
            date: function (date: string, item: string) {

                const formatDate = moment(date, 'MMM D YYYY').locale(store.state.currentLanguage != null ? store.state.currentLanguage.split('-')[0] : '');

                if (item === 'month') {
                    return formatDate.format('MMM');
                } else if (item === 'day') {
                    return formatDate.format('D');
                } else if (item === 'year') {
                    return formatDate.format('YYYY');
                } else {
                    return moment(date).format('MMM D YYYY');
                }
            },
            addToCalendar: function () {
                const eventsClient = new EventApiClient();
                eventsClient.addEventToCalendar(this.currentEvent.id, store.state.currentLanguage).then(
                    (result) => {
                        if (result !== null) {
                            const blob = new Blob([result.data], { type: 'text/calendar' });
                            const link = document.createElement('a');
                            link.href = window.URL.createObjectURL(blob);
                            link.download = 'event.ics';
                            link.click();
                        }
                    }
                );
            },

            addDay: function (date: Date) {
                const newDate = moment(date).add(1, 'd');
                return newDate.toDate();
            }
        }
    });
